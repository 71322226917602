<template>
  <div class="main_container" ref="to" @keydown="focusNoneInput($event)" tabindex="0">
    <div v-if="loaded">
      <div class="card-form">
        <div class="logo">
          <img alt="Vue logo" class="logo" src="/images/logo.png" />
        </div>
        <div
          class="title"
          style="font-weight: 400; font-size: x-small; color: rgb(50, 50, 50);"
        >
          version: {{ version }}
        </div>
        <div class="cardContent">
          <br />

          <div class="content" v-if="showLoginForm">
            <input
              class="textInput"
              type="text"
              v-model="email"
              placeholder="Email"
              ref="first"
              @keydown="focusFirstInput"
            />
          </div>
          <div class="content" v-if="showLoginForm">
            <input
              class="textInput"
              type="password"
              v-model="password"
              placeholder="Password"
              ref="second"
              @keydown="focusSecondInput"
            />
          </div>

          <p v-if="errors.length">
            <b>Mensaje:</b>
            <ul style="margin: 0%;">
              <li
                v-for="(error, index) in errors"
                :key="`error-${index}`"
                class="error"
              >
                {{ error }}
              </li>
            </ul>
          </p>

          <div
            class="buttonContent"
            @click="login"
            v-if="button && showLoginForm"
            style="margin-bottom: 5px;"
          >
            <div>
              <button
                class="button"
                type="submit"
                ref="en"
                @keydown="focusEnterInput"
              >
                {{ buttonText }}
              </button>
            </div>
          </div>
          <!-- Additional elements if needed -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { version } from "../../package.json";
import { Network } from "@capacitor/network"; // Capacitor's Network plugin

export default {
  name: "loginComponent",
  props: {
    loginAutorized: Boolean,
  },
  computed: {
    buttonText() {
      return "Conectar";
    },
  },
  created() {
    console.log("Created");
    let hostname_code = localStorage.getItem("hostname_code");
    if (hostname_code == null) {
      const randomCode = this.generateRandomCode();
      localStorage.setItem("hostname_code", randomCode);
      hostname_code = randomCode;
    }

    this.hostname_code = hostname_code;

    if (this.email && this.password && !this.autoLoginAttempted) {
      this.autoLoginAttempted = true;
      this.login();
    } else {
      // Show login form
      this.showLoginForm = true;
      this.loaded = true;

      // Focus the first input when the form is displayed
      this.$nextTick(() => {
        if (this.$refs.first) {
          this.$refs.first.focus();
        }
      });
    }

    Network.addListener("networkStatusChange", (status) => {
      console.log("Network status changed", status);
      this.networkConnected = status.connected;

      if (status.connected) {
        console.log("Network reconnected, retrying login...");
        location.reload();
      } else {
        this.displayError("Network disconnected, waiting for reconnection...");
      }
    });
  },
  data() {
    return {
      errors: [],
      email: localStorage.getItem("email") || "",
      password: localStorage.getItem("password") || "",
      REST_ENDPOINT: this.$REST_ENDPOINT,
      loading: false,
      button: true,
      version,
      hostname_code: "",
      loaded: false,
      showLoginForm: false,
      autoLoginAttempted: false,
    };
  },
  methods: {
    generateRandomCode(length = 10) {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"; // All letters and digits
      let result = "";
      const charactersLength = characters.length;

      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }

      return result;
    },
    logOut() {
      this.errors = [];
      this.showLoginForm = true;
    },
    login() {
      this.button = false;
      this.errors = [];
      this.loading = true;

      // Validate inputs
      if (!this.email) {
        this.displayError("El email es obligatorio");
        return;
      }
      if (!this.password) {
        this.displayError("La contraseña es obligatoria");
        return;
      }
      if (this.validateEmail()) {
        this.displayError("El email es invalido");
        return;
      }

      const params = new URLSearchParams();
      params.append("email", this.email);
      params.append("password", this.password);
      params.append("hostname", this.hostname_code);

      axios
        .post(`${this.REST_ENDPOINT}mobile.asp?Action=authenticate`, params)
        .then((result) => {
          if (
            result.data.status == "403" ||
            result.data.status == "401" ||
            result.data.status == "404"
          ) {
            this.displayError("El email o la contraseña son incorrectos");
            return;
          }
          if (result.data.status == "200") {
            // Successful login
            localStorage.setItem("email", this.email);
            localStorage.setItem("password", this.password);
            localStorage.setItem("login_token", result.data.access_token);
            localStorage.setItem("no_logout", result.data.user.no_logout);
            localStorage.setItem("user_id", result.data.user.id);

            const DataUser = {
              hostname: result.data.machines[0].user_machine.ip,
              username: result.data.machines[0].user_machine.nombre_maquina,
              port: result.data.machines[0].user_machine.puerto,
              password: result.data.machines[0].user_machine.password,
            };
            this.$emit("login-success", DataUser);
            return;
          }
        })
        .catch((error) => {
          console.error("Error during login: ", error);
          this.displayError("Error during login");
        });
    },
    displayError(errors) {
      setTimeout(() => (this.loading = false), 1000);

      setTimeout(() => this.errors.push(errors), 1000);

      setTimeout(() => (this.errors = []), 4000);
      setTimeout(() => (this.button = true), 4000);

      this.showLoginForm = true;

      return;
    },
    validateEmail() {
      if (
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.email)
      ) {
        return false;
      } else {
        return true;
      }
    },
    focusNoneInput(event) {
      // Usa el método focus() para enfocar el primer input
      const focusedElement = document.activeElement;
      console.log(`El elemento que tiene el foco es: ${focusedElement}`);
      if(this.$refs.to === focusedElement){
        if (event.key === 'ArrowDown') {
          event.preventDefault();
          this.$refs.first.focus();
        } else if (event.key === 'ArrowUp') {
          event.preventDefault();
          this.$refs.first.focus();
        }else if (event.key === 'Enter') {
          event.preventDefault();
          this.$refs.first.focus();
        }
      }
    },
    focusFirstInput(event) {
      const focusedElement = document.activeElement;
      if (this.$refs.first === focusedElement) {
        if (event.key === "ArrowDown" || event.key === "Enter") {
          event.preventDefault();
          this.$refs.second.focus();
        } else if (event.key === "ArrowUp") {
          event.preventDefault();
          this.$refs.en.focus();
        }
      }
    },
    focusSecondInput(event) {
      const focusedElement = document.activeElement;
      if (this.$refs.second === focusedElement) {
        if (event.key === "ArrowDown" || event.key === "Enter") {
          event.preventDefault();
          this.$refs.en.focus();
        } else if (event.key === "ArrowUp") {
          event.preventDefault();
          this.$refs.first.focus();
        }
      }
    },
    focusEnterInput(event) {
      const focusedElement = document.activeElement;
      if (this.$refs.en === focusedElement) {
        if (event.key === "ArrowDown") {
          event.preventDefault();
          this.$refs.first.focus();
        } else if (event.key === "ArrowUp") {
          event.preventDefault();
          this.$refs.second.focus();
        } else if (event.key === "Enter") {
          event.preventDefault();
          this.login();
        }
      }
    },
  },
};
</script>

<style>
ul {
  list-style-type: none;
}

.main_container {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10;
}

.logo {
  margin: 0 auto;
  display: center;
  padding: 4px 40px;
  width: 100%;
  justify-content: center;
}

.cardContent {
  width: auto;
  padding: 20px 0px;
  margin: 0 auto;
  position: relative;
}

.card-form {
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 50%;
  height: auto;
  color: white;
  padding: 20px;
  z-index: 20;

  border-radius: 20px;
}

@media screen and (max-width: 620px) {
  .card-form {
    width: auto;
  }
}

.title {
  text-align: left;
  font-size: 20px;
  width: 280px;
  color: black;
  position: absolute;
  left: 10px;
  bottom: 20px;
}

.error {
  color: #000000;
  font-weight: 300;
  font-size: 20px;
}
.buttonContent {
  margin-top: 10px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  width: 100%;
}

.button {
  background-color: #cd1e50;
  border: none;
  color: white;
  width: 15em; /* Adjust this width as needed */
  height: 50px;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
  cursor: pointer;
  transition: box-shadow 600ms cubic-bezier(0.33, 0.11, 0.02, 0.99),
    transform 600ms cubic-bezier(0.33, 0.11, 0.02, 0.99);
}

.button:hover {
  background-color: #000000;
  color: white;
  transform: scale(1) translateY(-0.2rem);
}

.button:active {
  background-color: #000000;
  color: white;
  transform: scale(1.1);
}

.textInput {
  width: 15em;
  background: rgb(248, 244, 244);
  border: 0px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  height: 50px;
  text-align: left;
  font-size: large;
  color: #000000;
  font-weight: 400;
}

.content {
  margin: 15px auto;
}
</style>
